import { PropsWithChildren, ReactNode } from "react"

function classNames(...classes: (string | undefined)[]) {
  return classes.filter(Boolean).join(" ")
}

export interface CardProps {
  footer?: ReactNode
  footerComponent?: ReactNode
  header?: ReactNode
  headerComponent?: ReactNode
  mediumTight?: boolean
  padding?: "tight" | "loose" | "normal"
  tight?: boolean
}

const paddingClassName = (padding: CardProps["padding"]) => {
  switch (padding) {
    case "tight":
      return "p-0"
    case "loose":
      return "px-8"
    default:
      return "px-4 py-5 sm:p-6"
  }
}

const CardRoot = ({
  children,
  header,
  footer,
  headerComponent,
  footerComponent,
  className,
  tight,
  padding = "normal",
  mediumTight,
}: PropsWithChildren<CardProps & { className?: string }>) => (
  <div
    className={classNames(
      className,
      "divide-y divide-charcoal-100 border border-charcoal-100 rounded-lg shadow",
    )}
  >
    {header && <CardHeader>{header}</CardHeader>}
    {headerComponent && headerComponent}
    <div
      className={
        tight
          ? ""
          : mediumTight
          ? "px-4 py-3 sm:px-2 sm:py-2 space-y-2" // px-4 py-2
          : "px-4 py-5 sm:p-6 space-y-4"
      }
    >
      {children}
    </div>
    {footer && <CardFooter>{footer}</CardFooter>}
    {footerComponent && footerComponent}
  </div>
)

const CardBody = () => <div className="px-4 py-5 sm:p-6">Body</div>
const CardHeader = ({ children }: PropsWithChildren) => (
  <div className="px-4 py-5 sm:px-6">{children}</div>
)
const CardFooter = ({ children }: PropsWithChildren) => (
  <div className="px-4 py-4 sm:px-6">{children}</div>
)

export const Card = CardRoot as typeof CardRoot & {
  Body: typeof CardBody
  Footer: typeof CardFooter
  Header: typeof CardHeader
}

Card.Body = CardBody
Card.Header = CardHeader
Card.Footer = CardFooter

export default Card
